import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home';
import Scanner from '../Scanner';
import CartonScanner from '../CartonScanner';
import Generator from '../Generator';
import Login from '../Login';
import QRCodeDataForm from '../QRCodeDataForm';
import ProtectedRoute from './ProtectedRoute';

const Router = () => {
  return (
    <Routes>
      {/* Protected Home Route */}
      <Route path='/' element={<ProtectedRoute element={<Home />} />} />

      {/* Public Login Route */}
      <Route path='/login' element={<Login />} />

      {/* Protected Routes*/}
      <Route path='/scanner' element={<ProtectedRoute element={<Scanner />} />} />
      <Route path='/cartonScanner' element={<ProtectedRoute element={<CartonScanner />} />} />
      <Route path='/generator' element={<ProtectedRoute element={<Generator />} />} />
      <Route path='/qrCodeForm' element={<ProtectedRoute element={<QRCodeDataForm />} />} />
    </Routes>
  );
}

export default Router;

