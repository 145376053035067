import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { IoMdDownload } from "react-icons/io";
import Button from "@mui/material/Button";
import CryptoJS from "crypto-js";
import axios from "axios";

const Generator = () => {
  const [inputValue, setInputValue] = useState("");
  const [encryptedValue, setEncryptedValue] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Get token from local storage if it exists.
    const storedToken = localStorage.getItem("jwtToken");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  // Function to handle the download of the QR code
  const download = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode.png"; // Add file extension for clarity
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  // Function to handle API upload
  const handleUpload = async () => {
    if (!token) {
      alert("You are not authenticated. Please login first.");
      navigate("/");
      return;
    }

    try {
      const response = await axios.post(
        "https://strapi-production-b6f7.up.railway.app/api/qr-codes",
        {
          "data": {
            "code" : inputValue
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token in the request header
          },
        }
      );

      // Handle success response
      console.log("Upload successful:", response.data);
      alert("Upload successful!"); // Optional: show success message
    } catch (error) {
      // Handle error response
      console.error("Upload failed:", error);
      alert("Upload failed. Please try again."); // Optional: show error message
    }
  };

  return (
    <div className="generator">
      <h2>QR Code Generator</h2>

      <div
        style={{
          height: "auto",
          background: "white",
          padding: "16px",
          margin: "0 auto",
          maxWidth: 256,
          width: "100%",
        }}
      >
        <QRCode
          size={4096}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={encryptedValue || " "}
          viewBox={`0 0 4096 4096`}
          id="QRCode"
        />
      </div>

      <div className="generator-input">
        <input
          type="text"
          onChange={(e) => {
            const value = e.target.value;
            setInputValue(value);
            const encrypted = CryptoJS.AES.encrypt(
              JSON.stringify(value),
              "oceangold"
            ).toString();
            setEncryptedValue(encrypted);
          }}
          placeholder="Enter text to generate QR code"
        />
        <button type="button" onClick={download}>
          <IoMdDownload />
        </button>
      </div>

      <div className="buttons">
        <Button variant="contained" onClick={handleUpload}>
          Upload
        </Button>
        <Button variant="contained" onClick={() => navigate("/")}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default Generator;
