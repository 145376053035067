import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Avatar, Button, TextField, Alert } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const Login = () => {
  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 280,
    margin: "19px auto",
    backgroundColor: "#E6F4F1",
    borderRadius: "12px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)", // Corrected rgba value to use decimal format
  };
  const avatarStyle = { backgroundColor: "#D9D9D9" };
  const btnstyle = { backgroundColor: "#1B6DA1", margin: "12px 0" };

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission default behavior
    setError(null); // Reset error before attempting login

    try {
      // Send login request to API
      const response = await axios.post(
        "https://strapi-production-b6f7.up.railway.app/api/auth/local",
        {
          identifier: username,
          password,
        }
      );
      localStorage.setItem("jwtToken", response.data.jwt);

      const response1 = await fetch(
        "https://strapi-production-b6f7.up.railway.app/api/users/me?populate=role",
        {
          headers: {
            authorization: `Bearer ${response.data.jwt}`,
          },
        }
      );
      const res = await response1.json();
      localStorage.setItem("role", res.role.name);

      navigate("/");

      // You can redirect the user to another page or update the UI as needed
    } catch (err) {
      // Handle error during login
      console.error(err);
      setError("Invalid username or password. Please try again.");
    }
  };

  return (
    <Grid>
      <Grid align="center">
        <img src={require("./images/Logo.png")} width={100} alt="Logo" />
        <h2>Ocean Gold Sea Food</h2>
      </Grid>
      <Paper elevation={12} style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon style={{ color: "#002A57" }} />
          </Avatar>
          <h2>Login</h2>
        </Grid>
        <form onSubmit={handleLogin}>
          <TextField
            id="username"
            value={username}
            label="Username"
            variant="standard"
            placeholder="Enter Your Username"
            fullWidth
            required
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <TextField
            id="password"
            value={password}
            label="Password"
            variant="standard"
            placeholder="Enter Your Password"
            type="password"
            fullWidth
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            style={btnstyle}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Login
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default Login;
