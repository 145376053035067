import { Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CryptoJS from 'crypto-js';

const CartonScanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 10,
      rememberLastUsedCamera: true,
      showTorchButtonIfSupported: true,
      showZoomSliderIfSupported: true,
      defaultZoomValueIfSupported: 1,
    });

    scanner.render(success, error);

    function success(result) {
      scanner.clear();
      var bytes = CryptoJS.AES.decrypt(result, 'oceangold');
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setScanResult(decryptedData);
    }

    function error(err) {
      console.warn(err);
    }
  }, []);

  return (
    <div className="scanner">
      <h2>QR Code Scanner</h2>
      <div className="scanner-div">
        {scanResult ? (
          <div>
            {scanResult.includes("http") ? (
              <>
                <h4>Link:</h4>
                <a href={scanResult}>{scanResult}</a>
              </>
            ) : (
              <>
                <h4>Success:</h4>
                <p>{scanResult}</p>
              </>
            )}
          </div>
        ) : (
          <div id="reader"></div>
        )}
      </div>
      
      <div className="buttons">
        <Button variant="contained" onClick={() => navigate("/")}>Back</Button>
      </div>

    </div>
  );
};

export default CartonScanner;
