import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Loader from "./Loader";

const QRCodeDataForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [setLoadingProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [startingRangeExists, setStartingRangeExists] = useState(false);
  const [endingRangeExists, setEndingRangeExists] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    processingMethod: "",
    supplier: "",
    processingSupervisor: "",
    packingSupervisor: "",
    catchMethod: "",
    productionTime: "",
    expiry: "",
    location: "",
    startingRange: "", // New Field
    endingRange: "", // New Field
  });

  const [token] = useState(localStorage.getItem("jwtToken") || "");
  const navigate = useNavigate();

  const validateRangeInput = (value) => {
    const regex = /^[A-Za-z]{5}[0-9]+$/;
    return regex.test(value);
  };

  const validateEndingRangeInput = (value) => {
    const startLetters = formData.startingRange.slice(0, 5);
    const startNumber = parseInt(formData.startingRange.slice(5), 10);
    const endLetters = value.slice(0, 5);
    const endNumber = parseInt(value.slice(5), 10);

    if (startLetters !== endLetters || startNumber >= endNumber) {
      return false;
    } else {
      return true;
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startingRange") {
      setStartingRangeExists(false);
      setEndingRangeExists(false);
    }
    if (name === "endingRange") {
      setEndingRangeExists(false);
    }
    if (
      value === "" ||
      ((name === "startingRange" || name === "endingRange") &&
        !validateRangeInput(value)) ||
      (name === "endingRange" && !validateEndingRangeInput(value))
    ) {
      setErrors((prevErrors) => {
        if (!prevErrors.includes(name)) {
          return [...prevErrors, name];
        }
        return prevErrors;
      });
    } else {
      setErrors((prevErrors) => {
        return prevErrors.filter((error) => error !== name);
      });
    }
    // if (name === "startingRange" && name === "endingRange") {
    //   value = calculateAsciiSum(value);
    // }
    if (name === "startingRange") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        startingRange: value,
        endingRange: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  //const calculateAsciiSum = (str) => {
   // let sum = 0;
  //  for (let i = 0; i < str.length; i++) {
   //   sum += str.charCodeAt(i); // Sum up ASCII values of each character
   // }
    //return sum;
  //};

  const validateFormForEmptySubmission = () => {
    let isFormValid = true;

    // Loop through each key in formData and check if it's empty
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        isFormValid = false;
        setErrors((prevErrors) => {
          if (!prevErrors.includes(key)) {
            return [...prevErrors, key];
          }
          return prevErrors;
        });
      }
    });

    // If there are errors, return them
    return isFormValid;
  };

  const checkIfStartingOrEndingRangeExists = async (range) => {
    try {
      const response = await axios.get(
        `https://strapi-production-b6f7.up.railway.app/api/qr-codes`,
        {
          params: {
            filters: {
              code: {
                $eq: range,
              },
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const doesExist = response.data.data.length > 0;

      if (doesExist) {
        console.log("Range already exists");
        return true;
      } else {
        console.log("Range does not exist");
        return false;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return false; // Return false in case of an error
    }
  };

  const postRangesToDB = async (data, batchSize = 10) => {
    debugger;
    setIsLoading(true);
    const startLetters = data.attributes.startingRange.slice(0, 3);
    const startNumber = parseInt(data.attributes.startingRange.slice(3), 10);
    // const endLetters = data.attributes.endingRange.slice(0, 3);
    const endNumber = parseInt(data.attributes.endingRange.slice(3), 10);

    const totalNumbers = endNumber - startNumber;
    const requests = [];

    // Create all the promises for the range
    for (let i = startNumber; i <= endNumber; i++) {
      const paddedNumber = String(i).padStart(3, "0");
      const payload = {
        data: {
          code: `${startLetters}${paddedNumber}`,
          form_id: data.id,
        },
      };

      requests.push(
        axios.post(
          "https://strapi-production-b6f7.up.railway.app/api/qr-codes",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      );

      if (requests.length === batchSize || i === endNumber) {
        try {
          await Promise.all(requests);
          setLoadingProgress((i / totalNumbers) * 100);
          console.log(`Batch of ${requests.length} requests completed.`);
        } catch (error) {
          console.error("Error in batch:", error);
        }

        requests.length = 0;
      }
    }
    setIsLoading(false);
    // for (let i = startNumber; i <= endNumber; i++) {
    //   console.log(`${data.id} : ${startLetters}${i}`);
    // }
  };

  const handleSubmit = async () => {
    debugger;
    const isFormValid = validateFormForEmptySubmission();
    if (!token) {
      alert("You are not authenticated. Please login first.");
      navigate("/");
      return;
    }
    if (errors.length > 0 || !isFormValid) {
      console.warn("Errors in :", errors.join(", "));
    } else {
      const SRangeExists = await checkIfStartingOrEndingRangeExists(
        formData.startingRange
      );
      const ERangeExists = await checkIfStartingOrEndingRangeExists(
        formData.endingRange
      );
      if (SRangeExists) {
        setStartingRangeExists(SRangeExists);
        setEndingRangeExists(ERangeExists);
        return;
      } else {
        try {
          const response = await axios.post(
            "https://strapi-production-b6f7.up.railway.app/api/qr-codes-data",
            {
              data: formData,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          debugger;
          alert("Data submitted successfully!");
          if (response?.data?.data.id && response?.data?.data.attributes) {
            postRangesToDB(response.data.data);
          }
          console.log(response.data);
        } catch (error) {
          console.error("Error submitting data:", error);
          alert("Failed to submit data. Please try again.");
        }
      }
    }
    return;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ padding: 20 }}>
          <h2>QR Code Data Input</h2>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("name") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Name is required.</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Processing Method"
                name="processingMethod"
                value={formData.processingMethod}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("processingMethod") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Processing Method is required.</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Supplier"
                name="supplier"
                value={formData.supplier}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("supplier") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Supplier is required.</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Processing Supervisor"
                name="processingSupervisor"
                value={formData.processingSupervisor}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("processingSupervisor") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>
                  * Processing Supervisor is required.
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Packing Supervisor"
                name="packingSupervisor"
                value={formData.packingSupervisor}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("packingSupervisor") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>
                  * Packing Supervisor is required.
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Catch Method"
                name="catchMethod"
                value={formData.catchMethod}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("catchMethod") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Catch Method is required.</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Production Time"
                name="productionTime"
                type="datetime-local"
                value={formData.productionTime}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("productionTime") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Production Time is required.</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Expiry Date"
                name="expiry"
                type="date"
                value={formData.expiry}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("expiry") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Expiry is required.</p>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("location") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>* Location is required.</p>
              </Grid>
            )}
            {/* New Field for Starting Range */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Starting Range"
                name="startingRange"
                value={formData.startingRange}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("startingRange") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>
                  * Starting Range is required in format i.e. AAA123 (3 letters
                  followed by numbers).
                </p>
              </Grid>
            )}
            {startingRangeExists && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>
                  * Starting Range is already existing.
                </p>
              </Grid>
            )}
            {/* New Field for Ending Range */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ending Range"
                name="endingRange"
                value={formData.endingRange}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            {errors.includes("endingRange") && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>
                  * Ending Range is required in format i.e. AAA123 (3 letters
                  followed by numbers) & greater than Starting Range.
                </p>
              </Grid>
            )}
            {endingRangeExists && (
              <Grid item sx={{ justifyContent: "start" }}>
                <p style={{ color: "red" }}>
                  * Ending Range is already existing.
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/")}
                fullWidth
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default QRCodeDataForm;
