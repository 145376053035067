import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const Home = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("role");
    navigate("/login");
  };
  return (
    <div className="home">
      <img src={require("./images/Logo.png")} alt="OCEAN GOLD SEAFOOD" width={164} />
      <h1>
        OGS QR Code <br></br>Scanner and Generator
      </h1>
      <div className="buttons">
        <Button variant="contained" onClick={() => navigate("/scanner")}>
          Scan
        </Button>
        <Button variant="contained" onClick={() => navigate("/cartonScanner")}>
          Box Scan
        </Button>
        {role === "Authenticated" && (
          <Button variant="contained" onClick={() => navigate("/generator")}>
            Box QR Generate
          </Button>
        )}
        {role !== "User" && (
          <Button variant="contained" onClick={() => navigate("/qrCodeForm")}>
            Form
          </Button>
        )}
      </div>
      <div className="buttons">
        <Button variant="contained" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Home;
