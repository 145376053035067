import React from 'react';
import './App.css';
import Router from "./router/router";

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
